
<template>
  <config-code apiname="category" idname="categoryId" labelname="รหัสประเภทสินค้า"></config-code>
</template>

<script>
import ConfigCode from "./ConfigCode.vue";
export default {
  components: {
    ConfigCode
  }
};
</script>

